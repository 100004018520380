<template>
    <v-row no-gutters>
        <v-col cols="12">
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Dashboard</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card tile elevation="4">
                    <v-toolbar color="white" dense flat>
                        <v-toolbar-title>Quick links</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-btn text color="blue darken-2" :to="{ name: 'user-dashboard' }">Users</v-btn>
                        <v-btn text color="blue darken-2" :to="{ name: 'account-dashboard' }">Accounts</v-btn>
                        <v-btn text color="blue darken-2" :to="{ name: 'realm-dashboard' }">Realms</v-btn>
                        <v-btn text color="blue darken-2" :to="{ name: 'report-list' }">Reports</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>
<script>
// import { /* mapState, */ mapGetters } from 'vuex';

export default {
};
</script>
